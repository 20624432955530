
















import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {},
  data() {
    return {};
  },
});
